import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import PageTemplate from "../templates/page";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          terms {
            nodes {
              name
            }
          }
          title
          uri
          slug
          postFields {
            authorName
            authorImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    width: 48
                  )
                }
              }
            }
            body
            date
            duration
            excerpt
            featureImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
      }
      allWpCourse(
        limit: 6
        sort: { fields: date, order: DESC }
        filter: { courseFields: { hasFree: { eq: true } } }
      ) {
        nodes {
          title
          terms {
            nodes {
              name
            }
          }
          slug
          courseFields {
            hasFree
            excerpt
            featureImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            coursePdf {
              mediaItemUrl
            }
            body
          }
        }
      }
      pageData: wpPage(slug: { eq: "homepage" }) {
        title
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        pageFields {
          pageSections {
            ... on WpPage_Pagefields_PageSections_HeroHomepageSection {
              background
              fieldGroupName
              heading
              subheading
              body
              button {
                target
                title
                url
              }
              button2 {
                target
                title
                url
              }
              backgroundImage {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
              backgroundVideo {
                mediaItemUrl
              }
            }
            ... on WpPage_Pagefields_PageSections_HeroPageSection {
              fieldGroupName
              heading
              subheading
              body
              alignment
              backgroundImage {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
              button {
                target
                title
                url
              }
              button2 {
                title
                url
                target
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout2 {
              alignment
              backgroundColor
              fieldGroupName
              body
              heading
              hasBorder
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
              button {
                target
                title
                url
              }
              button2 {
                target
                title
                url
              }
              subheading
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout3 {
              body
              fieldGroupName
              button {
                target
                title
                url
              }
              button2 {
                target
                title
                url
              }
              backgroundImage {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
              heading
              subheading
              items {
                body
                fieldGroupName
                heading
                image {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      original {
                        height
                        width
                      }
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                        width: 40
                      )
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout4 {
              body
              fieldGroupName
              heading
              button {
                target
                title
                url
              }
              button2 {
                target
                title
                url
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout5 {
              body
              fieldGroupName
              heading
              subheading
              items {
                description
                heading
                icon {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      original {
                        height
                        width
                      }
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                        width: 32
                      )
                    }
                  }
                }
                link {
                  target
                  title
                  url
                }
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout6 {
              alignment
              body
              fieldGroupName
              hasTwoColumn
              hasContentRight
              heading
              button {
                target
                title
                url
              }
              button2 {
                target
                title
                url
              }
              backgroundImage {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout7 {
              body
              fieldGroupName
              button {
                target
                title
                url
              }
              heading
              items {
                day
                price
                time
                title
                type
                link {
                  target
                  title
                  url
                }
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout8 {
              fieldGroupName
              heading
              subheading
              items {
                body
                heading
                button {
                  target
                  title
                  url
                }
                button2 {
                  target
                  title
                  url
                }
                image {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      original {
                        height
                        width
                      }
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                        width: 48
                      )
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout9 {
              body
              fieldGroupName
              hasTab
              heading
              subheading
              items {
                address
                addressLink
                fieldGroupName
                heading
                mapLink
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout10 {
              body
              fieldGroupName
              heading
              image {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
              items {
                body
                heading
                icon {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      original {
                        height
                        width
                      }
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout11 {
              body
              fieldGroupName
              heading
              subheading
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout12 {
              body
              fieldGroupName
              heading
              subheading
              viewAllButtonText
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout13 {
              body
              fieldGroupName
              heading
              reviews {
                ... on WpReview {
                  id
                  slug
                  title
                  reviewFields {
                    city
                    name
                    postcode
                    review
                    reviewStar
                    image {
                      altText
                      localFile {
                        publicURL
                        childImageSharp {
                          original {
                            height
                            width
                          }
                          gatsbyImageData(
                            formats: [AUTO, WEBP]
                            quality: 100
                            transformOptions: {
                              cropFocus: CENTER
                              fit: CONTAIN
                            }
                            layout: CONSTRAINED
                            placeholder: BLURRED
                            width: 56
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout14 {
              body
              bottomContent
              fieldGroupName
              heading
              bottomHeading
              button {
                target
                title
                url
              }
              faqs {
                ... on WpFaq {
                  title
                  faqFields {
                    body
                  }
                }
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout15 {
              body
              fieldGroupName
              heading
              items {
                ... on WpPost {
                  id
                  title
                  slug
                }
              }
              subheading
              button {
                target
                title
                url
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout16 {
              fieldGroupName
              heading
              subheading
              monthlyPricingItems {
                price
                type
                icon {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      original {
                        height
                        width
                      }
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                }
                fieldGroupName
                features {
                  heading
                }
                link {
                  target
                  title
                  url
                }
              }
              perTermPricingItems {
                price
                type
                fieldGroupName
                features {
                  heading
                }
                icon {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      original {
                        height
                        width
                      }
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                }
                link {
                  target
                  title
                  url
                }
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout17 {
              body
              fieldGroupName
              heading
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout18 {
              body
              fieldGroupName
              heading
              button {
                target
                title
                url
              }
              button2 {
                title
                target
                url
              }
              subheading
              items {
                heading
                body
                image {
                  altText
                  localFile {
                    publicURL
                    childImageSharp {
                      original {
                        height
                        width
                      }
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
            }
            ... on WpPage_Pagefields_PageSections_SectionLayout19 {
              body
              heading
              subheading
              fieldGroupName
            }
          }
        }
      }
    }
  `);

  return <PageTemplate data={data} />;
};

export default IndexPage;
